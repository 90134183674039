import React, { useRef, useEffect, useState } from "react";


const ProgressBar = ({ percentage }) => {
  const progressBarRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const progressBarNode = progressBarRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });

    observer.observe(progressBarNode);
    return () => observer.unobserve(progressBarNode);
  }, []);

  return (
    <div className="progress-bar-container">
      <div
        className={`progress-bar ${isVisible ? "animate" : ""}`}
        style={{ maxWidth: `${percentage}%` }}
        ref={progressBarRef}
      />
    </div>
  );
};

export default ProgressBar;

import { useState } from 'react';
import { Carousel } from 'antd';
import HeroImg from "../assets/honda.png";
import AboutImg from "../assets/about.jpg";
import Slider1 from "../assets/1.png";
import Slider2 from "../assets/2.png";
import Slider3 from "../assets/3.png";
import Slider4 from "../assets/4.png";
import Slider5 from "../assets/5.png";
import Slider6 from "../assets/6.png";
import Slider7 from "../assets/7.png";
import Slider8 from "../assets/8.png";
import Slider9 from "../assets/9.png";
import Slider10 from "../assets/10.png";
import Slider11 from "../assets/11.png";
import Slider12 from "../assets/12.png";
import Slider13 from "../assets/13.png";
import Slider14 from "../assets/14.png";
import Slider15 from "../assets/15.png";
import Slider16 from "../assets/16.png";
import Dyna from "../assets/dyna.jpg"
import ProgressBar from './ProgressBar';
import Product from "./Product";


export default function About() {


  const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };



  return (
    <div className="about" id='aboutSec'>


      <div className=''>
        <div className='aboutContainer'>
          <div className='singleAbout'>
            <h3>ABOUT DYNAVOLT</h3>
            <ul>
              <li>- First manufacturer worldwide, to bring
                the revolutionary 'Nano-gel technology'
                based maintenance-free motorcycle
                batteries. Present over 90 countries worldwide.The research-based company has
                obtained 395 plus battery patents so far. </li>
              <li>- Dynavolt patented SMOOTH welding
                technology and TTP welding technology
                represent global battery industry
                benchmarks. More than 80% of the global sales comes
                from Europe, North/South America. Dynavolt is the first manufacturer worldwide, who has brought the "Gel Nanotechnology" of the maintenance-free motorcycle battery to market. The company covers the majority of motorcycle batteries in the world. The annual production is about 15 million batteries, which are sold in over 90 countries worldwide.</li>
            </ul>
          </div>
          <div>
            <img src={HeroImg} />
          </div>
        </div>

      </div>

      <section className='progressSec'  >
        <h1>Why Choose Dynavolt "Nano-Gel" Battery?</h1>
        <div>
          <div>
            <div id='structureSec'></div>
            <div className='batteryStructure'>
              <h1 >Dynavolt Battery Structure</h1>
              <div className="str">
              <img src={AboutImg} />
              </div>
            </div>
          </div>
          <div>

            <div className='singleProgress'>
              <p>Higher Cold Cranking Ability</p>
              <div>
                <div className='progressTop'>
                  <ProgressBar percentage={100} />
                  <span>DYNAVOLT BATTERY</span>
                </div>
                <div className='progressBot'>
                  <ProgressBar percentage={50} />
                  <span>OTHER BATTERY</span>
                </div>
              </div>
              <span>Dynavolt batteries have 33% better cold cranking ability(*European Norm: EN Certified) than normal batteries.</span>
            </div>

            <div className='singleProgress'>
              <p>Lower Internal Resistance</p>
              <div>
                <div className='progressTop'>
                  <ProgressBar percentage={100} />
                  <span>DYNAVOLT BATTERY</span>
                </div>
                <div className='progressBot'>
                  <ProgressBar percentage={50} />
                  <span>OTHER BATTERY</span>
                </div>
              </div>
              <span>The TTP welding technology in Dynavolt batteries results in 25% less internal resistance than normal batteries.</span>
            </div>

            <div className='singleProgress'>
              <p>Lower Self Discharge Rate</p>
              <div>
                <div className='progressTop'>
                  <ProgressBar percentage={100} />
                  <span>DYNAVOLT BATTERY</span>
                </div>
                <div className='progressBot'>
                  <ProgressBar percentage={50} />
                  <span>OTHER BATTERY</span>
                </div>
              </div>
              <span>Dynavolt batteries have up to 4X less self-discharge rate.</span>
            </div>

            <div className='singleProgress'>
              <p>Higher Charge Retention after 6 months</p>
              <div>
                <div className='progressTop'>
                  <ProgressBar percentage={100} />
                  <span>DYNAVOLT BATTERY</span>
                </div>
                <div className='progressBot'>
                  <ProgressBar percentage={50} />
                  <span>OTHER BATTERY</span>
                </div>
              </div>
              <span>Dynavolt batteries are proven to have 80% charge retention after 6 months at 20°C temperature.</span>
            </div>


            <div className='singleProgress'>
              <p>Wider Operating Temperature</p>
              <div>
                <div className='progressTop'>
                  <ProgressBar percentage={100} />
                  <span>DYNAVOLT BATTERY</span>
                </div>
                <div className='progressBot'>
                  <ProgressBar percentage={50} />
                  <span>OTHER BATTERY</span>
                </div>
              </div>
              <span>Dynavolt batteries have wider operating temperature of -30°C to 50°C
                while normal batteries have the operating temperature of -20°C to 40°C.</span>
            </div>


            <div className='singleProgress end'>
              <p>Ideal battery for any Nepalese ride conditions.</p>
              <div>
                <div className='progressTop'>
                  <ProgressBar percentage={100} />

                </div>
                <div className='progressBot'>
                  <ProgressBar percentage={100} />

                </div>
              </div>
              <span>Right from Nepalgunj (45°C) to Mustang (-25°C), Dynavolt battery has got you covered!</span>
            </div>
          </div>
        </div>

      </section>
      {/* 
      <section className='c-section'>
      <h1>Why Choose Dynavolt "Nano-Gel" Battery?</h1>
        <div class="singleBox left" data-aos="fade-right" data-aos-duration="3000">
          <div>
            <img src={AboutImg} />
            <div>
              <h3>MG4L-BS</h3>
              <div class="singleDescription">
                Actual Capacity: 4AH
                Cold Cranking Amperes (-18°C EN Standard):45A
                Operating Discharge Temprature Range: -30°C to 50°C
                Upgrade from ETZ-4L (Actual Capacity:3Ah)
                Recommended for Honda Shine and similar two wheelers
              </div>
            </div>
          </div>
        </div>
        <div class="singleBox right" data-aos="fade-right" data-aos-duration="3000">
          <div>
           
            <div>
              <h3>MG4L-BS</h3>
              <div class="singleDescription">
                Actual Capacity: 4AH
                Cold Cranking Amperes (-18°C EN Standard):45A
                Operating Discharge Temprature Range: -30°C to 50°C
                Upgrade from ETZ-4L (Actual Capacity:3Ah)
                Recommended for Honda Shine and similar two wheelers
              </div>
            </div>
            <img src={AboutImg} />
          </div>
        </div>
        <div class="singleBox left" data-aos="fade-right" data-aos-duration="3000">
          <div>
            <img src={AboutImg} />
            <div>
              <h3>MG4L-BS</h3>
              <div class="singleDescription">
                Actual Capacity: 4AH
                Cold Cranking Amperes (-18°C EN Standard):45A
                Operating Discharge Temprature Range: -30°C to 50°C
                Upgrade from ETZ-4L (Actual Capacity:3Ah)
                Recommended for Honda Shine and similar two wheelers
              </div>
            </div>
          </div>
        </div>
        <div class="singleBox right" data-aos="fade-right" data-aos-duration="3000">
          <div>
           
            <div>
              <h3>MG4L-BS</h3>
              <div class="singleDescription">
                Actual Capacity: 4AH
                Cold Cranking Amperes (-18°C EN Standard):45A
                Operating Discharge Temprature Range: -30°C to 50°C
                Upgrade from ETZ-4L (Actual Capacity:3Ah)
                Recommended for Honda Shine and similar two wheelers
              </div>
            </div>
            <img src={AboutImg} />
          </div>
        </div>
      </section> */}


      <Product />
      <div id='contact'></div>
      {/* <div className="socialSec">
        <img src={Dyna} />
        <div>
          <h1>IRON MONK NEPAL PVT. LTD.</h1>
          <p>Address: Teku, kathmandu</p>

        </div>
      </div> */}
     

      <section className="contact-sec sec-pad" id='contact'>

        <div className="container">
          <h1 className="section-title">Contact us / Enquiry</h1>
          <div className="row">
            <div className="col-md-6">
              <div className="contact-detail">


                <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.711275755298!2d85.30762381518362!3d27.695317232627158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19ac08d75923%3A0x563b6328b2a1274c!2sZontes%20Motorcycle%20Nepal!5e0!3m2!1sen!2snp!4v1676887407177!5m2!1sen!2snp' ></iframe>
                
                <span>
                  <a href="https://www.facebook.com/DynavoltNepal?mibextid=ZbWKwL" className="fb"><i className="fa-brands fa-facebook"></i></a>
                  <a href="http://m.me/108619228671872" className="twitter"><i class="fa-brands fa-facebook-messenger"></i></a>
                  <a href="https://instagram.com/dynavolt_nepal?igshid=YzgyMTM2MGM=" className="insta"><i class="fa-brands fa-instagram"></i></a>
                </span>
              </div>
            </div>

            <div className="col-md-6">
              <form action="#" className="contFrm" method="POST">
                <div className="row">
                  <div className="col-sm-6">
                    <input type="text" name="name" placeholder="Your Name" className="inptFld" required />
                  </div>

                  <div className="col-sm-6">
                    <input type="email" name="email" placeholder="Email Address" className="inptFld" required />
                  </div>

                  <div className="col-sm-6">
                    <input type="tel" name="phone" placeholder="Phone Number" className="inptFld" required />
                  </div>

                  <div className="col-sm-6">
                    <input type="text" name="sub" placeholder="Subject" className="inptFld" required />
                  </div>

                  <div className="col-12">
                    <textarea className="inptFld" rows="" cols="" placeholder="Your Message..." required></textarea>
                  </div>

                  <div className="col-12">
                    <button>Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>


        </div>
      </section>

      <div className='gallaryContainer'>
        <h1 className="section-title">OEM Partners</h1>

        <Carousel afterChange={onChange} slidesToShow={5} autoplay autoplaySpeed={2000}>

          <div>
            <img src={Slider1} alt="" />
          </div>
          <div>
            <img src={Slider2} alt="" />
          </div>
          <div>
            <img src={Slider3} alt="" />
          </div>
          <div>
            <img src={Slider4} alt="" />
          </div>
          <div>
            <img src={Slider5} alt="" />
          </div>
          <div>
            <img src={Slider6} alt="" />
          </div>
          <div>
            <img src={Slider7} alt="" />
          </div>
          <div>
            <img src={Slider8} alt="" />
          </div>
          <div>
            <img src={Slider9} alt="" />
          </div>
          <div>
            <img src={Slider10} alt="" />
          </div>
          <div>
            <img src={Slider11} alt="" />
          </div>
          <div>
            <img src={Slider12} alt="" />
          </div>
          <div>
            <img src={Slider13} alt="" />
          </div>
          <div>
            <img src={Slider14} alt="" />
          </div>
          <div>
            <img src={Slider15} alt="" />
          </div>
          <div>
            <img src={Slider16} alt="" />
          </div>
        </Carousel>
      </div>


    </div>
  )
}
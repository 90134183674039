import { BrowserRouter as Router, Route,  Routes } from "react-router-dom";

import Home from "../themes/Home";
import Contact from "../components/Contact";
import ProductPage from "../themes/ProductPage";


function MyRoutes() {
  return (
    <div className="Routes">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/contact" element={<Contact />}/>
          <Route exact path="/product" element={<ProductPage />}/>
        </Routes>
       
      </Router>
    </div>
  );
}

export default MyRoutes;

import Navbar from "../components/Navbar";
import Product from "../components/Product";
import Footer from "../components/Footer";
import ProductBanner from "../assets/productBanner.jpg";

export default function ProductPage() {
    return(
        <div>
          <Navbar />
          <div className="contactBanner">
            <img src={ProductBanner} />
          </div>
          <Product />
          <Footer />
        </div>
    )
}

import Navbar from "./Navbar";
import Hero from "./Hero";
import About from "./About";
import Footer from "./Footer";
import ProductBanner from "../assets/productBanner.jpg";
import Dyna from "../assets/dyna.jpg"


export default function Contact() {
    return(
        <div className="contact">
          <Navbar />
          <div className="contactBanner">
            <img src={ProductBanner} />
          </div>
          <div className="socialSec">
            <img src={Dyna} />
            <div>
                <h1>IRON MONK NEPAL PVT. LTD.</h1>
                <p>Address: Teku, kathmandu</p>
                
            </div>
          </div>
          <About />
          <Footer />
        </div>
    )
}

import Iron from "../assets/ironMonk.png";

export default function Footer() {

    return(
        <div className="footer">
             <img src={Iron} />
            <div className="footerWrap">
            <div className="footerFirst">
           
           <div>
           
           <h1>IRON MONK NEPAL PVT. LTD.</h1>
           <p>SOLE AUTHORISED IMPORTER AND DISTRIBUTOR FOR NEPAL</p>
           </div>
       </div>
       <div>
           <h3>ADDRESS: Gongabu, Kathmandu | Contact No: +9779802822887</h3>
           {/* <p>Web: www.ironmonknepal.com</p> */}
       </div>
            </div>
        </div>
    )
}

import './App.css';
import Navbar from './components/Navbar';
import MyRoutes from './router/router';

function App() {
  return (
    <div className="App">
      <MyRoutes />
    </div>
  );
}

export default App;
 
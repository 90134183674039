import { Affix, Layout, Menu } from 'antd';
import LazyLoad from 'react-lazy-load-image-component';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Dynavolt from "../assets/dynavolt.png"

const { Header } = Layout;

const Navbar = () => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsNavbarVisible(true);
      } else {
        setIsNavbarVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='headerFixed'>
      <Affix offsetTop={0}>
      <Header
        className="header"
        style={{ backgroundColor: isNavbarVisible ? '#001529' : 'transparent' }}
      >
        <div className="logo">
          {/* <LazyLoad height={32} placeholder={<img src="https://via.placeholder.com/32x32" alt="Logo" />}>
            <img src="https://via.placeholder.com/32x32" alt="Logo" />
          </LazyLoad> */}
        </div>
        {/* {isNavbarVisible && (
          <Menu  mode="horizontal">
           <div className='innerNav'>
            <Link to="/"><h3>DYNAVOLT</h3></Link>
            <div>
            <Menu.Item key="1">Products</Menu.Item>
            <Menu.Item key="3"><a href="#structureSec">Battery Structure</a></Menu.Item>
            <Menu.Item key="4"><a href="#productsSec">Products</a></Menu.Item>
            <Menu.Item key="1"><a href="#aboutSec">About Us</a></Menu.Item>
            <Menu.Item key="2"><Link to="/contact">Contact Us</Link></Menu.Item>

            </div>
           </div>
           
          </Menu>
        )} */}
        <Menu  mode="horizontal">
           <div className='innerNav'>
            <Link to="/"><img src={Dynavolt}/></Link>
            <div>
            {/* <Menu.Item key="1">Products</Menu.Item> */}
            <Menu.Item key="1"><a href="#aboutSec">About Us</a></Menu.Item>
            <Menu.Item key="3"><a href="#structureSec">Battery Structure</a></Menu.Item>
            <Menu.Item key="4"><a href="#productsSec">Products</a></Menu.Item>
         
            <Menu.Item key="2"><a href="#contact">Contact Us</a></Menu.Item>

            </div>
           </div>
           
          </Menu>
      </Header>
    </Affix>
    </div>
  );
};

export default Navbar;

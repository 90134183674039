import { useEffect, useState } from "react";
import axios from 'axios';
import {  notification, Space } from 'antd';
import MobileBanner from "../assets/mobileBanner.jpg";
import MobileBanner1 from "../assets/mobileBanner1.jpg";
import HeroImg from "../assets/hero.jpg";
import HeroImg1 from "../assets/banner2.jpg";
import HeroImg2 from "../assets/banner3.jpg";
import HeroImg3 from "../assets/hero1.jpg";
import HeroImg4 from "../assets/3-1.jpg";
import { Select } from 'antd';
import Battery from "../assets/about.jpg";
import Slider1 from "../assets/4AH.png";
import Slider2 from "../assets/5ah.png";
import Slider3 from "../assets/6ah.png";
import Slider4 from "../assets/7ah.png";
import Slider5 from "../assets/8ah.png";
import Slider6 from "../assets/9ah.png";
import Slider7 from "../assets/10ah.png";
import Slider8 from "../assets/11ah.png";
import Slider9 from "../assets/12ah.png";
import { Carousel, Radio, Button, Modal } from 'antd';


                      
import Splendor from "../assets/Super Spendor.jpg";
import Xpulse200 from "../assets/X Pulse.jpg";
import Xpulse  from "../assets/X Pulse.jpg"; 
import HunkNew  from "../assets/Hunk New.jpg";
import HunkUpto2011  from "../assets/HunkUpto2011.jpg"; 
import SuperSplendorBS6  from "../assets/SuperSplendorBS6.jpg"; 
import SuperSplendor  from "../assets/SuperSplendor.jpg";  
import GlamourBS6  from "../assets/GlamourBS6.jpg";
import GlamourFiBS4  from "../assets/GlamourFiBS4.jpg";  
import Hunkupto2011  from "../assets/Hunkupto20111.jpg";  
import Destini125  from "../assets/Destini125.jpg";  
import Maestro  from "../assets/Maestro.jpg";  
import Pleasure  from "../assets/Pleasure.jpg"; 
import KarismaZMR  from "../assets/KarismaZMR.jpg";  
import Shine  from "../assets/Shine.jpg";  
import CBUnicorn  from "../assets/CBUnicorn.jpg";  
import Dio  from "../assets/DioR.jpg"; 
import Dio2012  from "../assets/Dio2012.jpg";  
import Aviator  from "../assets/Aviator.jpg"; 
import AviatorUpto2012  from "../assets/AviatorUpto2012.jpg"; 
import Hornet160R  from "../assets/Hornet160R.jpg"; 
import Hornet160  from "../assets/Hornet160.jpg"; 
import Honda125cc150cc  from "../assets/Honda125cc150cc.jpg";
import XR150L  from "../assets/XR150L.jpg";
import CBR250R  from "../assets/CBR250R.jpg";
import CBR300R  from "../assets/CBR300R.jpg";
import XR190L  from "../assets/XR190L.jpg";
import Avenger180  from "../assets/Avenger180.jpg";
import Avenger200  from "../assets/Avenger200.jpg";
import Pulsarbelow160cc  from "../assets/Pulsarbelow160cc.jpg";
import Pulsarabove160cc  from "../assets/Pulsarabove160cc.jpg";
import Dominar  from "../assets/Dominar.jpg";
import Avenger160  from "../assets/Avenger160Street.jpg";
import Discover  from "../assets/Discover.jpg";


import ApacheRTR160BS6 from "../assets/ApacheRTR160BS6.jpg";
import ApacheRTR2004VBS6 from "../assets/ApacheRTR2004VBS6.jpg";
import ApacheRR310BS4 from "../assets/ApacheRR310BS4.jpg";
import ApacheRTR1604VBS6 from "../assets/ApacheRTR1604VBS6.jpg";
import ApacheRTR150 from "../assets/ApacheRTR150.jpg";
import ApacheRTR1604V from "../assets/ApacheRTR1604V.jpg";
import ApacheRTR160Fi4V from "../assets/ApacheRTR160Fi4V.jpg";
import ApacheRTR160Fi from "../assets/ApacheRTR160Fi.jpg";
import ApacheRTR160 from "../assets/ApacheRTR160.jpg";
import ApacheRTR180 from "../assets/ApacheRTR180.jpg";
import ApacheRTR200 from "../assets/ApacheRTR200.jpg";
import WegoBS4 from "../assets/WegoBS4.jpg";
import Wego from "../assets/Wego.jpg";
import JupiterBS6 from "../assets/JupiterBS6.jpg";
import JupiterClassic from "../assets/JupiterClassic.jpg";
import JupiterGrand from "../assets/JupiterGrand.jpg";
import JupiterZXDisc from "../assets/JupiterZXDisc.jpg";
import Ntorq125BS6 from "../assets/Ntorq125BS6.jpg";
import Ntorq125BS4 from "../assets/Ntorq125BS4.jpg";
import FZV3BS6 from "../assets/FZV3BS6.jpg";
import FZSV3BS6 from "../assets/FZSV3BS6.jpg";
import FZ15 from "../assets/FZ15.jpg";
import FZ16 from "../assets/FZ16.jpg";
import FZ25250CcBS6 from "../assets/FZ25250CcBS6.jpg";
import YahamaFZ25Fi250cc from "../assets/YahamaFZ25Fi250cc.jpg";
import YamahaYZFR3 from "../assets/YamahaYZFR3.jpg";
import Fazer25Fi250cc from "../assets/Fazer25Fi250cc.jpg";
import MT from "../assets/MT.jpg";
import BenelliTNT13 from "../assets/BenelliTNT13.jpg";
import BenelliTNT150i from "../assets/BenelliTNT150i.jpg";
import Benelli300above from "../assets/Benelli300above.jpg";
import VR150 from "../assets/VR150.jpg";
import VR220 from "../assets/VR220.jpg";
import NK150 from "../assets/NK150.jpg";
import NK250 from "../assets/NK250.jpg";
import KTMall from "../assets/KTMall.jpg";
import ROYALENFIELDall from "../assets/ROYALENFIELDall.jpg";
import JAWAall from "../assets/JAWAall.jpg";
import GixxerSF150 from "../assets/GixxerSF150.jpg";
import GixxerSF250 from "../assets/GixxerSF250.jpg";







export default function Hero() {
    const [valueModel, setvalueModel] = useState(null);
    const [valueVehicle, setvalueVehicle] = useState('');
    const [model, setModel] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [image, setImage] = useState();
    const [name, setName] = useState("");
    const [description, setDescription] = useState();
    const [price, setPrice] = useState("");
    const [bikeImg, setBikeImg ] = useState([]);
    const [showNot, setShowNot] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone:'',
        subject:'',
        message:'',
        enquiry:''
      });
console.log(formData)

      const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Successful',
      description:
        'Form was submitted successfully, we will get in touch with you.',
    });
  };


    const onChangeV = (value) => {
       
        setIsOpen(false);
        setvalueModel('');
        if (value === 'Suzuki') {

            setModel([
                {
                    value: 'Gixxer SF150',
                    label: 'Gixxer SF150',
                },
                {
                    value: 'Gixxer SF250',
                    label: 'Gixxer SF250',
                }
            ])
        } else if (value === 'Hero') {
            setModel([
                // {
                //     value: 'Splendor',
                //     label: 'Splendor',
                // },
                {
                    value: 'Xpulse200',
                    label: 'Xpulse200',
                },
                {
                    value: 'Xpulse',
                    label: 'Xpulse',
                },
                {
                    value: 'Hunk New',
                    label: 'Hunk New',
                },
                {
                    value: 'Hunk (Upto 2011)',
                    label: 'Hunk (Upto 2011)',
                },
                {
                    value: 'Super Splendor (BS6)',
                    label: 'Super Splendor (BS6)',
                },
                {
                    value: 'Super Splendor',
                    label: 'Super Splendor',
                },
                {
                    value: 'Glamour(BS6)',
                    label: 'Glamour(BS6)',
                },
                {
                    value: 'Glamour Fi (BS4)',
                    label: 'Glamour Fi (BS4)',
                },
                {
                    value: 'Destini 125',
                    label: 'Destini 125',
                },
                {
                    value: 'Maestro',
                    label: 'Maestro',
                },{
                    value: 'Pleasure',
                    label: 'Pleasure',
                },{
                    value: 'Karisma ZMR',
                    label: 'Karisma ZMR',
                }
               
                
            ])
        } else if (value === 'Honda') {
            setModel([
                {
                    value: 'Shine',
                    label: 'Shine',
                },
                {
                    value: 'CB Unicorn',
                    label: 'CB Unicorn',
                },
                {
                    value: 'Dio',
                    label: 'Dio',
                },
                {
                    value: 'Aviator',
                    label: 'Aviator',
                },
                {
                    value: 'Aviator (Upto 2012)',
                    label: 'Aviator (Upto 2012)',
                },
                {
                    value: 'Hornet 160R',
                    label: 'Hornet 160R',
                },
                {
                    value: 'Hornet 160',
                    label: 'Hornet 160',
                },
                {
                    value: 'Honda 125cc/150cc',
                    label: 'Honda 125cc/150cc',
                },
                {
                    value: 'XR150L',
                    label: 'XR150L',
                },
                {
                    value: 'CBR 250R',
                    label: 'CBR 250R',
                },
                {
                    value: 'CBR 300R',
                    label: 'CBR 300R',
                },
                {
                    value: 'XR190L',
                    label: 'XR190L',
                }
            ])
        }
        else if (value === 'Bajaj') {
            setModel([
                {
                    value: 'Avenger 180',
                    label: 'Avenger 180',
                },
                {
                    value: 'Avenger 200',
                    label: 'Avenger 200',
                },
                {
                    value: 'Pulsar below 160cc',
                    label: 'Pulsar below 160cc',
                },
                {
                    value: 'Pulsar above 160cc',
                    label: 'Pulsar above 160cc',
                },
                {
                    value: 'Dominar',
                    label: 'Dominar',
                },
                {
                    value: 'Avenger 160',
                    label: 'Avenger 160',
                },
                {
                    value: 'Discover',
                    label: 'Discover',
                }
            ])
        }
        else if (value === 'Tvs') {
            setModel([
                {
                    value: 'Apache RTR 160 (BS6)',
                    label: 'Apache RTR 160 (BS6)',
                },
                {
                    value: 'Apache RTR 200 4V (BS6)',
                    label: 'Apache RTR 200 4V (BS6)',
                },
                {
                    value: 'Apache RR310 (BS4)',
                    label: 'Apache RR310 (BS4)',
                },
                {
                    value: 'Apache RTR 160 4V (BS6)',
                    label: 'Apache RTR 160 4V (BS6)',
                },
                {
                    value: 'Apache RTR 150',
                    label: 'Apache RTR 150',
                },
                {
                    value: 'Apache RTR 160 4V',
                    label: 'Apache RTR 160 4V',
                },
                {
                    value: 'Apache RTR 160 Fi 4V',
                    label: 'Apache RTR 160 Fi 4V',
                },
                {
                    value: 'Apache RTR 160 Fi',
                    label: 'Apache RTR 160 Fi',
                },
                {
                    value: 'Apache RTR 160',
                    label: 'Apache RTR 160',
                },
                {
                    value: 'Apache RTR 180',
                    label: 'Apache RTR 180',
                },
                {
                    value: 'Apache RTR 200',
                    label: 'Apache RTR 200',
                },
                {
                    value: 'Wego (BS4)',
                    label: 'Wego (BS4)',
                },
                {
                    value: 'Wego',
                    label: 'Wego',
                },
                {
                    value: 'Jupiter (BS6)',
                    label: 'Jupiter (BS6)',
                },
                {
                    value: 'Jupiter Classic',
                    label: 'Jupiter Classic',
                },
                {
                    value: 'Jupiter Grand',
                    label: 'Jupiter Grand',
                },
                {
                    value: 'Jupiter ZX-Disc',
                    label: 'Jupiter ZX-Disc',
                },
                {
                    value: 'Ntorq 125 (BS6)',
                    label: 'Ntorq 125 (BS6)',
                },
                {
                    value: 'Ntorq 125 (BS4)',
                    label: 'Ntorq 125 (BS4)',
                }

            ])
        }
        else if (value === 'Yamaha') {
            setModel([
                {
                    value: 'FZ V3 (BS6)',
                    label: 'FZ V3 (BS6)',
                },
                {
                    value: 'FZS V3 (BS6)',
                    label: 'FZS V3 (BS6)',
                },
                {
                    value: 'FZ 15',
                    label: 'FZ 15',
                },
                {
                    value: 'FZ 16',
                    label: 'FZ 16',
                },
                {
                    value: 'FZ 25 (250Cc) (BS6)',
                    label: 'FZ 25 (250Cc) (BS6)',
                },
                {
                    value: 'Yahama FZ 25 Fi (250cc)',
                    label: 'Yahama FZ 25 Fi (250cc)',
                },
                {
                    value: 'Yamaha YZF R3',
                    label: 'Yamaha YZF R3',
                },
                {
                    value: 'Fazer 25 Fi (250cc)',
                    label: 'Fazer 25 Fi (250cc)',
                },
                {
                    value: 'MT',
                    label: 'MT',
                }
                

            ])
        }
        else if (value === 'Benelli') {
            setModel([
                {
                    value: 'BenelliTNT135',
                    label: 'BenelliTNT135',
                },
                {
                    value: 'BenelliTNT150i',
                    label: 'BenelliTNT150i',
                },
                {
                    value: 'Benelli(300 & above)',
                    label: 'Benelli(300 & above)',
                }

            ])
        }
        else if (value === 'Hartford') {
            setModel([
                {
                    value: 'VR150',
                    label: 'VR150',
                },
                {
                    value: 'VR220',
                    label: 'VR220',
                }

            ])
        }
        else if (value === 'CFMOTO') {
            setModel([
                {
                    value: '150NK',
                    label: '150NK',
                },
                {
                    value: '250NK',
                    label: '250NK',
                }
            ])
        }
        else if (value === 'Ktm') {
            setModel([
                {
                    value: 'KTM(all)',
                    label: 'KTM(all)',
                }
            ])
        }
        else if (value === 'Royal Enfield') {
            setModel([
                {
                    value: 'ROYAL ENFIELD (all)',
                    label: 'ROYAL ENFIELD (all)',
                }
            ])
        }
        else if (value === 'Jawa') {
            setModel([
                {
                    value: 'JAWA(all)',
                    label: 'JAWA(all)',
                }
            ])
        }
        else if (value === 'Crossfire') {
            setModel([
                {
                    value: 'Crossfire',
                    label: 'Crossfire',
                }
            ])
        }
    };

    const onChangeM = (value) => {
       
        setvalueModel(value);
        setIsOpen(false);

        if ((value === '' )) {
            setName("MG4L-BS");
            setImage(Slider2);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 4Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 45A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-4L (Actual capacity : 3Ah)</li><li>Recommended for Honda Shine and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Shine")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Honda Shine and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Super Splendor (BS6)")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Super Splendor and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Jupiter (BS6)")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Jupiter and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Ntorq 125 (BS6)")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Ntorq and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "Avenger 160")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Bajaj Pulsar 150 and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "Avenger 180")) {
            setName("MG9-BS");
            setImage(Slider7);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 120A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-9R (Actual capacity : 9Ah)</li><li>Recommended for Avenger 180 and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "Avenger 200")) {
            setName("MG9-BS");
            setImage(Slider7);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 120A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-9R (Actual capacity : 9Ah)</li><li>Recommended for Avenger 200 and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "Hornet 160")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Hornet 160 and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "CB Unicorn")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for CB Unicorn and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "Destini 12")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Destini 12 and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "Discover")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Discover and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "FZ V3 (BS6)")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for FZ V3 (BS6) and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "FZS V3 (BS6)")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for FZS V3 (BS6) and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "Glamour(BS6)")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Glamour(BS6) and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "Maestro")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Maestro and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "MT")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for MT and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "Pleasure")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Pleasure and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "Pulsar below 160cc")) {
            setName("MG5L-BS");
            setImage(Slider9);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 75A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-5L (Actual capacity : 5Ah)</li><li>Recommended for Pulsar below 160cc and similar two-wheelers</li></ul></>)
        }
        else if ((value ===  "Pulsar above 160cc")) {
            setName("MG9-BS-C");
            setImage(Slider7);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 120A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-9R (Actual capacity : 8Ah)</li><li>Recommended for Pulsar above 160cc and similar two-wheelers</li></ul></>)
        }
        
       
        
        
       
        
        else if ((value === "Jupiter ZX-Disc")) {
            setName("MG5-3B");
            setImage(Slider8);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 65A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li><li>Recommended for Jupiter ZX-Disc and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Jupiter Grand")) {
            setName("MG5-3B");
            setImage(Slider8);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 65A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li><li>Recommended for Jupiter Grand and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Super Splendor")) {
            setName("MG5-3B");
            setImage(Slider8);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 65A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li><li>Recommended for Super Splendor and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Wego")) {
            setName("MG5-3B");
            setImage(Slider8);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 65A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li><li>Recommended for Wego and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Aviator (Upto 2012)")) {
            setName("MG5-3B");
            setImage(Slider8);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 65A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li><li>Recommended for Aviator (Upto 2012) and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Dio (2012)")) {
            setName("MG5-3B");
            setImage(Slider8);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 65A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li><li>Recommended for Dio (2012) and similar two-wheelers</li></ul></>)
        }
        else if ((value === "FZ 15")) {
            setName("MG5-3B");
            setImage(Slider8);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 65A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li><li>Recommended for Yamaha FZ 15 and similar two-wheelers</li></ul></>)
        }
        else if ((value === "FZ 16")) {
            setName("MG5-3B");
            setImage(Slider8);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 65A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li><li>Recommended for Yamaha FZ 16 and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Glamour Fi (BS4)")) {
            setName("MG5-3B");
            setImage(Slider8);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 65A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li><li>Recommended for Glamour Fi (BS4) and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Hunk (Upto 2011)")) {
            setName("MG5-3B");
            setImage(Slider8);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 65A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li><li>Recommended for Hunk (Upto 2011) and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Jupiter Classic")) {
            setName("MG5-3B");
            setImage(Slider8);
            setPrice("3,299");
            setDescription(<><ul><li>Actual capacity : 5Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 65A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li><li>Recommended for Jupiter Classic and similar two-wheelers</li></ul></>)
        }
        
        
        
        
        

        


        else if ((value === "Crossfire")) {
            setName("MG7ZS");
            setImage(Slider4);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 6Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 80A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from YTZ7S (Actual capacity : 6Ah)</li><li>Recommended for Crossfire and similar two-wheelers</li></ul></>)
        }



        else if ((value === "BenelliTNT135")) {
            setName("MG7A-BS");
            setImage(Slider6);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 80A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from YTZ7S (Actual capacity : 7Ah)</li><li>Recommended for Benelli TNT135 and similar two-wheelers</li></ul></>)
        }
        else if ((value === "BenelliTNT150i")) {
            setName("MG7A-BS");
            setImage(Slider6);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 80A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from YTZ7S (Actual capacity : 7Ah)</li><li>Recommended for BenelliTNT150i and similar two-wheelers</li></ul></>)
        }
        else if ((value === "VR150")) {
            setName("MG7A-BS");
            setImage(Slider6);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 80A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from YTZ7S (Actual capacity : 7Ah)</li><li>Recommended for VR150 and similar two-wheelers</li></ul></>)
        }
        else if ((value === "VR220")) {
            setName("MG7A-BS");
            setImage(Slider6);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 80A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from YTZ7S (Actual capacity : 7Ah)</li><li>Recommended for VR220 and similar two-wheelers</li></ul></>)
        }



        
        else if ((value === "FZ 25 (250Cc) (BS6)")) {
            setName("MG7L-BS");
            setImage(Slider1);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for FZ 25 (250Cc) (BS6) and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Gixxer SF150")) {
            setName("MG7L-BS");
            setImage(Slider1);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for Gixxer SF150 and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Gixxer SF250")) {
            setName("MG7L-BS");
            setImage(Slider1);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for Gixxer SF250 and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Hunk New")) {
            setName("MG7L-BS");
            setImage(Slider1);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for Hunk New and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Karisma ZMR")) {
            setName("MG7L-BS");
            setImage(Slider1);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for Karisma ZMR and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Xpulse")) {
            setName("MG7L-BS");
            setImage(Slider1);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for Xpulse and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Xpulse200")) {
            setName("MG7L-BS");
            setImage(Slider1);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for Xpulse200 and similar two-wheelers</li></ul></>)
        }
        else if ((value === "150NK")) {
            setName("MG7L-BS");
            setImage(Slider1);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for 150NK and similar two-wheelers</li></ul></>)
        }
        else if ((value === "CBR 300R")) {
            setName("MG7L-BS");
            setImage(Slider1);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for CBR 300R and similar two-wheelers</li></ul></>)
        }
        else if ((value === "CBR 250R")) {
            setName("MG7L-BS");
            setImage(Slider1);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for CBR 250R and similar two-wheelers</li></ul></>)
        }
        






        else if ((value === "Fazer 25 Fi (250cc)" )) {
            setName("MG7A-4A");
            setImage(Slider5);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for Fazer 25 Fi (250cc) and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Yahama FZ 25 Fi (250cc)" )) {
            setName("MG7A-4A");
            setImage(Slider5);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for Yahama FZ 25 Fi (250cc) and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Yamaha YZF R3" )) {
            setName("MG7A-4A");
            setImage(Slider5);
            setPrice("4,699");
            setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-7L (Actual capacity : 7Ah)</li><li>Recommended for Yamaha YZF R3 and similar two-wheelers</li></ul></>)
        }
       
        





        // else if ((value === "TNT15" )) {
        //     setName("MG7-A");
        //     setImage(Slider6);
        //     setPrice("5,699");
        //     setDescription(<><ul><li>Actual capacity : 7Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 105A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from YTX7A (Actual capacity : 7Ah)</li><li>Recommended for Benelli TNT150i and similar two-wheelers</li></ul></>)
        // }





        else if ((value === "KTM(all)")) {
            setName("MG9-BS");
            setImage(Slider7);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 120A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-9R (Actual capacity : 8Ah)</li><li>Recommended for KTM(all) and similar two-wheelers</li></ul></>)
        }
        else if ((value === "ROYAL ENFIELD (all)")) {
            setName("MG9-BS");
            setImage(Slider7);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 120A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-9R (Actual capacity : 8Ah)</li><li>Recommended for ROYAL ENFIELD (all) and similar two-wheelers</li></ul></>)
        }
        else if ((value === "JAWA(all)")) {
            setName("MG9-BS");
            setImage(Slider7);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 120A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-9R (Actual capacity : 8Ah)</li><li>Recommended for JAWA(all) and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Pulsar(180cc & above)")) {
            setName("MG9-BS");
            setImage(Slider7);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 120A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-9R (Actual capacity : 8Ah)</li><li>Recommended for Pulsar(180cc & above) and similar two-wheelers</li></ul></>)
        }
        else if ((value === "TVS Apache BS6")) {
            setName("MG9-BS");
            setImage(Slider7);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 120A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETZ-9R (Actual capacity : 8Ah)</li><li>Recommended for TVS Apache BS6 and similar two-wheelers</li></ul></>)
        }





        else if ((value === "250NK")) {
            setName("MG9-4B");
            setImage(Slider3);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 125A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li><li>Recommended for 250NK and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Apache RTR 150")) {
            setName("MG9-4B");
            setImage(Slider3);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 125A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li><li>Recommended for Apache RTR 150 and similar two-wheelers</li></ul></>)
        }else if ((value === "Apache RTR 160 4V (BS6)")) {
            setName("MG9-4B");
            setImage(Slider3);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 125A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li><li>Recommended for Apache RTR 160 4V (BS6) and similar two-wheelers</li></ul></>)
        }
        
        else if ((value === "Apache RTR 160 4V")) {
            setName("MG9-4B");
            setImage(Slider3);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 125A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li><li>Recommended for Apache RTR 160 4V and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Apache RTR 160 Fi 4V")) {
            setName("MG9-4B");
            setImage(Slider3);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 125A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li><li>Recommended for Apache RTR 160 Fi 4V and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Apache RTR 160 Fi")) {
            setName("MG9-4B");
            setImage(Slider3);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 125A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li><li>Recommended for Apache RTR 160 Fi and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Apache RTR 160")) {
            setName("MG9-4B");
            setImage(Slider3);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 125A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li><li>Recommended for Apache RTR 160 and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Apache RTR 180")) {
            setName("MG9-4B");
            setImage(Slider3);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 125A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li><li>Recommended for Apache RTR 180 and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Apache RTR 200 4V Fi")) {
            setName("MG9-4B");
            setImage(Slider3);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 125A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li><li>Recommended for Apache RTR 200 4V Fi and similar two-wheelers</li></ul></>)
        }
        else if ((value === "Apache RTR 200")) {
            setName("MG9-4B");
            setImage(Slider3);
            setPrice("5,999");
            setDescription(<><ul><li>Actual capacity : 9Ah</li><li>Cold Cranking Amperes (-18° EN Standard) : 125A</li><li>Operating Discharge Temperature Range : -30°C to 50°C</li><li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li><li>Recommended for Apache RTR 200 and similar two-wheelers</li></ul></>)
        }
    };
    const onSearch = (value) => {
    
    };



    const handleToggle = () => {
        setIsOpen(true);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowNot('');
        formData.enquiry = name;
      
        try {
          const response = await axios.post('https://bknd.dynavoltnepal.com/users/', formData);
          console.log('Form submitted successfully!', response.data);
          // Handle success case, e.g., show a success message
         setShowNot('yes');
      
        } catch (error) {
          console.error('Error submitting form:', error);
          // Handle error case, e.g., show an error message
          setShowNot('');
        }
      };




      useEffect(()=> {
        if(showNot === 'yes'){
            openNotificationWithIcon('success');
        setIsModalOpen(false);
        }
    },[showNot])


    return (
        <div className="hero">
            {contextHolder}
            <div className="deskBanner">
            <Carousel dotPosition="right" autoplay autoplaySpeed={2000}>
                <div>
                    <img src={HeroImg} alt="dynavolt hero image" className="heroImg" />
                </div>
                <div>
                    <img src={HeroImg1} alt="dynavolt hero image" className="heroImg nd" />
                </div>
                <div>
                    <img src={HeroImg2} alt="dynavolt hero image" className="heroImg rd" />
                </div>
                <div>
                    <img src={HeroImg3} alt="dynavolt hero image" className="heroImg" />
                </div>
               
            </Carousel>
            </div>

            <div className="mobileBanner">
            <Carousel dotPosition="right" autoplay autoplaySpeed={2000}>
                <div>
                    <img src={MobileBanner} alt="dynavolt hero image" className="heroImg" />
                </div>
                <div>
                    <img src={MobileBanner1} alt="dynavolt hero image" className="heroImg" />
                </div>
               
            </Carousel>
            </div>

            <div className="selectSec">
                <h3>CHOOSE YOUR BATTERY</h3>
                <div className="selectHolder">
                    <Select
                        showSearch
                        placeholder="VEHICLE"
                        optionFilterProp="children"
                        onChange={onChangeV}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={[
                            {
                                value: 'Suzuki',
                                label: 'Suzuki',
                            },
                            {
                                value: 'Hero',
                                label: 'Hero',
                            },
                            {
                                value: 'Honda',
                                label: 'Honda',
                            },
                            {
                                value: 'Bajaj',
                                label: 'Bajaj',
                            },
                            {
                                value: 'Tvs',
                                label: 'Tvs',
                            },
                            {
                                value: 'Yamaha',
                                label: 'Yamaha',
                            },
                            {
                                value: 'Benelli',
                                label: 'Benelli',
                            },
                            {
                                value: 'CFMOTO',
                                label: 'CFMOTO',
                            },
                            {
                                value: 'Ktm',
                                label: 'Ktm',
                            },
                            {
                                value: 'Royal Enfield',
                                label: 'Royal Enfield',
                            },
                            {
                                value: 'Jawa',
                                label: 'Jawa',
                            },
                            {
                                value: 'Crossfire',
                                label: 'Crossfire',
                            }
                        ]}
                    />

                    <Select
                        allowClear={true}

                        showSearch
                        placeholder="MODEL"
                        optionFilterProp="children"
                        onChange={onChangeM}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        value={valueModel}
                        options={model}
                    />

{/* 
                    <Select

                        showSearch
                        placeholder="FUEL"
                        optionFilterProp="children"

                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={[
                            {
                                value: 'petrol',
                                label: 'Petrol',
                            }
                        ]}
                    /> */}

                    <button onClick={handleToggle}>FIND NOW</button>
                </div>
            </div>

            {valueModel !== null ? <div className="container" style={{ height: isOpen ? "600px" : "0" }}>

                <div
                    className={`content ${isOpen ? "open" : ""}`}
                    style={{ height: isOpen ? "100%" : "0" }}
                >
                    <div>
                        <img src={image} />
                        <h4>{name}</h4>
                        {description}

                        <table>
                            <thead>
                                <th></th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>MRP</b></td>
                                    <td>Rs.{price}</td>
                                </tr>
                            </tbody>
                        </table>

                        <button onClick={showModal}>
                            Enquiry product
                        </button>
                        <Modal title="Product Enquiry Form" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                            <form onSubmit={handleSubmit} className="contFrm" >
                                <div className="row">
                                    <div className="col-sm-6">
                                        <input type="text" name="name" placeholder="Your Name" className="inptFld" required  onChange={(event) => setFormData({ ...formData, name: event.target.value })}/>
                                    </div>

                                    <div className="col-sm-6">
                                        <input type="email" name="email" placeholder="Email Address" className="inptFld" required  onChange={(event) => setFormData({ ...formData, email: event.target.value })}/>
                                    </div>

                                    <div className="col-sm-6">
                                        <input type="tel" name="phone" placeholder="Phone Number" className="inptFld" required  onChange={(event) => setFormData({ ...formData, phone: event.target.value })}/>
                                    </div>

                                    <div className="col-sm-6">
                                        <input type="text" name="subject" placeholder="Subject" className="inptFld" required  onChange={(event) => setFormData({ ...formData, subject: event.target.value })}/>
                                    </div>

                                    <div className="col-12">
                                        <textarea className="inptFld" rows="" cols="" placeholder="Your Message..." required name="message"  onChange={(event) => setFormData({ ...formData, message: event.target.value })}></textarea>
                                    </div>

                                    <div className="col-12">
                                    <button type="submit">Submit</button>
                                
                                    </div>
                                </div>
                            </form>
                        </Modal>
                    </div>

                    {valueModel === "Gixxer SF150" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={GixxerSF150} />
                       </div>
                    </div>:null}
                    {valueModel === "Gixxer SF250" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={GixxerSF250} />
                       </div>
                    </div>:null}

                    {valueModel === "Splendor" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Splendor} />
                       </div>
                    </div>:null}

                    {valueModel === "Xpulse200" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Xpulse200} />
                       </div>
                    </div>:null}

                    {valueModel === "Xpulse" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Xpulse} />
                       </div>
                    </div>:null}

                    {valueModel === "Hunk New" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={HunkNew} />
                       </div>
                    </div>:null}

                    {valueModel === "Hunk (Upto 2011)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={HunkUpto2011} />
                       </div>
                    </div>:null}

                    {valueModel === "Super Splendor (BS6)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={SuperSplendorBS6} />
                       </div>
                    </div>:null}

                    {valueModel === "Super Splendor" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={SuperSplendor} />
                       </div>
                    </div>:null}

                    {valueModel === "Glamour(BS6)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={GlamourBS6} />
                       </div>
                    </div>:null}

                    {valueModel === "Glamour Fi (BS4)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={GlamourFiBS4} />
                       </div>
                    </div>:null}

                    {valueModel === "Hunk (upto 2011)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Hunkupto2011} />
                       </div>
                    </div>:null}

                    {valueModel === "Destini 125" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Destini125} />
                       </div>
                    </div>:null}

                    {valueModel === "Maestro" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Maestro} />
                       </div>
                    </div>:null}

                    {valueModel === "Pleasure" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Pleasure} />
                       </div>
                    </div>:null}

                    {valueModel === "Karisma ZMR" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={KarismaZMR} />
                       </div>
                    </div>:null}

                    {valueModel === "Shine" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Shine} />
                       </div>
                    </div>:null}

                    {valueModel === "CB Unicorn" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={CBUnicorn} />
                       </div>
                    </div>:null}

                    {valueModel === "Dio" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Dio} />
                       </div>
                    </div>:null}

                    {valueModel === "Dio (2012)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Dio2012} />
                       </div>
                    </div>:null}

                    {valueModel === "Aviator" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Aviator} />
                       </div>
                    </div>:null}

                    {valueModel === "Aviator (Upto 2012)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={AviatorUpto2012} />
                       </div>
                    </div>:null}

                    {valueModel === "Hornet 160R" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Hornet160R} />
                       </div>
                    </div>:null}

                    {valueModel === "Hornet 160" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Hornet160} />
                       </div>
                    </div>:null}

                    {valueModel === "Honda 125cc/150cc" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Honda125cc150cc} />
                       </div>
                    </div>:null}

                    {valueModel === "XR150L" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={XR150L} />
                       </div>
                    </div>:null}

                    {valueModel === "CBR 250R" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={CBR250R} />
                       </div>
                    </div>:null}

                    {valueModel === "CBR 300R" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={CBR300R} />
                       </div>
                    </div>:null}

                    {valueModel === "XR190L" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={XR190L} />
                       </div>
                    </div>:null}

                    {valueModel === "Avenger 180" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Avenger180} />
                       </div>
                    </div>:null}

                    
                    {valueModel === "Pulsar below 160cc" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Pulsarbelow160cc} />
                       </div>
                    </div>:null}

                    {valueModel === "Pulsar above 160cc" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Pulsarabove160cc} />
                       </div>
                    </div>:null}

                    {valueModel === "Dominar" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Dominar} />
                       </div>
                    </div>:null}

                    {valueModel === "Avenger 160" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Avenger160} />
                       </div>
                    </div>:null}

                    {valueModel === "Avenger 200" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Avenger200} />
                       </div>
                    </div>:null}

                    
                    {valueModel === "Discover" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Discover} />
                       </div>
                    </div>:null}

                     {valueModel === "Apache RTR 160 (BS6)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ApacheRTR160BS6} />
                       </div>
                    </div>:null}

                    {valueModel === "Apache RTR 200 4V (BS6)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ApacheRTR2004VBS6} />
                       </div>
                    </div>:null}

                    {valueModel === "Apache RR310 (BS4)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ApacheRR310BS4} />
                       </div>
                    </div>:null}

                    {valueModel === "Apache RTR 160 4V (BS6)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ApacheRTR1604VBS6} />
                       </div>
                    </div>:null}

                    {valueModel === "Apache RTR 150" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ApacheRTR150} />
                       </div>
                    </div>:null}

                    {valueModel === "Apache RTR 160 4V" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ApacheRTR1604V} />
                       </div>
                    </div>:null}

                    {valueModel === "Apache RTR 160 Fi 4V" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ApacheRTR160Fi4V} />
                       </div>
                    </div>:null}

                    {valueModel === "Apache RTR 160 Fi" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ApacheRTR160Fi} />
                       </div>
                    </div>:null}

                    {valueModel === "Apache RTR 160" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ApacheRTR160} />
                       </div>
                    </div>:null}

                    {valueModel === "Apache RTR 180" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ApacheRTR180} />
                       </div>
                    </div>:null}

                    {valueModel === "Apache RTR 200" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ApacheRTR200} />
                       </div>
                    </div>:null}

                    {valueModel === "Wego (BS4)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={WegoBS4} />
                       </div>
                    </div>:null}

                    {valueModel === "Wego" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Wego} />
                       </div>
                    </div>:null}

                    {valueModel === "Jupiter (BS6)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={JupiterBS6} />
                       </div>
                    </div>:null}

                    {valueModel === "Jupiter Classic" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={JupiterClassic} />
                       </div>
                    </div>:null}

                    {valueModel === "Jupiter Grand" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={JupiterGrand} />
                       </div>
                    </div>:null}

                    {valueModel === "Jupiter ZX-Disc" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={JupiterZXDisc} />
                       </div>
                    </div>:null}

                    {valueModel === "Ntorq 125 (BS6)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Ntorq125BS6} />
                       </div>
                    </div>:null}

                    {valueModel === "Ntorq 125 (BS4)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Ntorq125BS4} />
                       </div>
                    </div>:null}

                    {valueModel === "FZ V3 (BS6)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={FZV3BS6} />
                       </div>
                    </div>:null}

                    {valueModel === "FZS V3 (BS6)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={FZSV3BS6} />
                       </div>
                    </div>:null}

                    {valueModel === "FZ 15" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={FZ15} />
                       </div>
                    </div>:null}

                    {valueModel === "FZ 16" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={FZ16} />
                       </div>
                    </div>:null}

                    {valueModel === "FZ 25 (250Cc) (BS6)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={FZ25250CcBS6} />
                       </div>
                    </div>:null}

                    {valueModel === "Yahama FZ 25 Fi (250cc)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={YahamaFZ25Fi250cc} />
                       </div>
                    </div>:null}

                    {valueModel === "Yamaha YZF R3" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={YamahaYZFR3} />
                       </div>
                    </div>:null}

                    {valueModel === "Fazer 25 Fi (250cc)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Fazer25Fi250cc} />
                       </div>
                    </div>:null}

                    {valueModel === "MT" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={MT} />
                       </div>
                    </div>:null}

                    {valueModel === "BenelliTNT13" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={BenelliTNT13} />
                       </div>
                    </div>:null}

                    {valueModel === "BenelliTNT150i" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={BenelliTNT150i} />
                       </div>
                    </div>:null}

                    {valueModel === "Benelli(300 & above)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={Benelli300above} />
                       </div>
                    </div>:null}

                    {valueModel === "VR150" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={VR150} />
                       </div>
                    </div>:null}

                    {valueModel === "VR220" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={VR220} />
                       </div>
                    </div>:null}

                    {valueModel === "150NK" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={NK150} />
                       </div>
                    </div>:null}

                    {valueModel === "250NK" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={NK250} />
                       </div>
                    </div>:null}

                    {valueModel === "KTM(all)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={KTMall} />
                       </div>
                    </div>:null}

                    {valueModel === "ROYAL ENFIELD (all)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={ROYALENFIELDall} />
                       </div>
                    </div>:null}

                    {valueModel === "JAWA(all)" ?  <div className="bikeGallery">
                      <div className="singleGallary">
                         <img src={JAWAall} />
                       </div>
                    </div>:null}
                  
                    
                    
                    


                    

                    

                </div>
            </div> : null}

           
        </div>
    )
}

import Slider1 from "../assets/MG7L-BS-C.jpg";
import Slider2 from "../assets/MG4L-BS-C.jpg";
import Slider3 from "../assets/MG9-4B-C.jpg";
import Slider4 from "../assets/MG7ZS-C.jpg";
import Slider5 from "../assets/MG7A-4A-C.jpg";
import Slider6 from "../assets/MG7A-BS-C.jpg";
import Slider7 from "../assets/MG9-BS-C.jpg";
import Slider8 from "../assets/MG5-3B.jpg";
import Slider9 from "../assets/MG4L-BS-C.jpg";
import { useState,useEffect } from "react";
import { Button, Modal,Carousel,notification, Space } from 'antd';
import axios from 'axios';



export default function Product() {

    const [model, setModel] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [enquiry, setEnquiry] = useState('');
    const [showNot, setShowNot] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone:'',
        subject:'',
        message:'',
        enquiry:''
      });
console.log(formData)

      const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Successful',
      description:
        'Form was submitted successfully, we will get in touch with you.',
    });
  };



    const showModal = (battery) => {
        setIsModalOpen(true);
        setEnquiry(battery);
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };




      const handleSubmit = async (event) => {
        event.preventDefault();
        formData.enquiry = enquiry;
      
        try {
          const response = await axios.post('https://bknd.dynavoltnepal.com/users/', formData);
          console.log('Form submitted successfully!', response.data);
          // Handle success case, e.g., show a success message
         setShowNot('yes');
      
        } catch (error) {
          console.error('Error submitting form:', error);
          // Handle error case, e.g., show an error message
          setShowNot('');
        }
      };




      useEffect(()=> {
        if(showNot === 'yes'){
            openNotificationWithIcon('success');
        setIsModalOpen(false);
        }
    },[showNot])

    return (
        <div id="productsSec">
             {contextHolder}
            <h1 className="title">DYNAVOLT PRODUCTS</h1>
            <ul class="mystyle-products lg" >
                <li class="product" onClick={() => showModal("MG7L-BS-C")}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider1} />
                        <h3>MG7L-BS-C</h3>
                        <ul>
                          <li>Actual capacity : 7Ah</li>
                          <li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li>
                          <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                          <li>Upgrade from ETZ-7L (Actual capacity : 6Ah)</li>
                          <li>Recommended for Honda Shine and similar two-wheelers</li>
                        </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 4,699 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i><div>Enquiry Product</div></a>
                </li>
                <li class="product" onClick={() => showModal("MG4L-BS-C")}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider2} />
                        <h3>MG4L-BS-C</h3>
                         <ul>
                         <li>Actual capacity : 4Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 45A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETZ-4L (Actual capacity : 3Ah)</li>
                        <li>Recommended for Honda Shine and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 3,299 </span> </ins>
                           
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i><div>Enquiry Product</div></a>
                </li>
                <li class="product" onClick={() => showModal("MG9-4B-C")}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider3} />
                        <h3>MG9-4B-C</h3>
                        <ul>
                         <li>Actual capacity : 9Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 125A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li>
                        <li>Recommended for Apache RTR 180 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 5,999 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i><div>Enquiry Product</div></a>
                </li>
                <li class="product" onClick={() => showModal("MG7ZS-C")}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider4} />
                        <h3>MG7ZS-C</h3>
                        <ul>
                         <li>Actual capacity : 6Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 80A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from YTZ7S (Actual capacity : 6Ah)</li>
                        <li>Recommended for Crossfire 250 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 4,699 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i><div>Enquiry Product</div></a>
                </li>
                <li class="product" onClick={() => showModal("MG7A-4A-C")}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider5} />
                        <h3>MG7A-4A-C</h3>
                        <ul>
                         <li>Actual capacity : 7Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 95A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETX-7R (Actual capacity : 7Ah)</li>
                        <li>Recommended for Honda Unicorn 150 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 4,699 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i><div>Enquiry Product</div></a>
                </li>
                <li class="product" onClick={() => showModal("MG7A-BS-C")}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider6} />
                        <h3>MG7A-BS-C</h3>
                        <ul>
                         <li>Actual capacity : 7Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 105A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from YTX7A (Actual capacity : 7Ah)</li>
                        <li>Recommended for Benelli TNT150i and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 5,699 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i><div>Enquiry Product</div></a>
                </li>
                <li class="product" onClick={() => showModal("MG9-BS-C")}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider7} />
                        <h3>MG9-BS-C</h3>
                        <ul>
                         <li>Actual capacity : 9Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 120A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETZ-9R (Actual capacity : 8Ah)</li>
                        <li>Recommended for Royal Enfield 350 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 5,999 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i><div>Enquiry Product</div></a>
                </li>
                <li class="product" onClick={() => showModal("MG5-3B-C")}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider8} />
                        <h3>MG5-3B-C</h3>
                        <ul>
                         <li>Actual capacity : 5Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 65A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li>
                        <li>Recommended for Yamaha FZ 150 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 3,299 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i><div>Enquiry Product</div></a>
                </li>

                <li class="product" onClick={() => showModal("MG5L-BS-C")}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider9} />
                        <h3>MG5L-BS-C</h3>
                        <ul>
                         <li>Actual capacity : 5Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 75A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETZ-5L (Actual capacity : 4Ah)</li>
                        <li>Recommended for Bajaj Pulsar 150 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 3,299 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i><div>Enquiry Product</div></a>
                </li>
            </ul>

            <div class="mystyle-products sm" >
            <Carousel dotPosition="bottom" autoplay autoplaySpeed={3000} >
                <li class="product" onClick={showModal}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider1} />
                        <h3>MG7L-BS-C</h3>
                        <ul>
                          <li>Actual capacity : 7Ah</li>
                          <li>Cold Cranking Amperes (-18°C EN Standard) : 100A</li>
                          <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                          <li>Upgrade from ETZ-7L (Actual capacity : 6Ah)</li>
                          <li>Recommended for Honda Shine and similar two-wheelers</li>
                        </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 4,699 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i></a>
                </li>
                <li class="product" onClick={showModal}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider2} />
                        <h3>MG4L-BS-C</h3>
                         <ul>
                         <li>Actual capacity : 4Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 45A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETZ-4L (Actual capacity : 3Ah)</li>
                        <li>Recommended for Honda Shine and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 3,299 </span> </ins>
                           
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i></a>
                </li>
                <li class="product" onClick={showModal}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider3} />
                        <h3>MG9-4B-C</h3>
                        <ul>
                         <li>Actual capacity : 9Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 125A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETX-9R (Actual capacity : 9Ah)</li>
                        <li>Recommended for Apache RTR 180 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 5,999 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i></a>
                </li>
                <li class="product" onClick={showModal}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider4} />
                        <h3>MG7ZS-C</h3>
                        <ul>
                         <li>Actual capacity : 6Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 80A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from YTZ7S (Actual capacity : 6Ah)</li>
                        <li>Recommended for Crossfire 250 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 4,699 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i></a>
                </li>
                <li class="product" onClick={showModal}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider5} />
                        <h3>MG7A-4A-C</h3>
                        <ul>
                         <li>Actual capacity : 7Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 95A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETX-7R (Actual capacity : 7Ah)</li>
                        <li>Recommended for Honda Unicorn 150 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 4,699 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i></a>
                </li>
                <li class="product" onClick={showModal}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider6} />
                        <h3>MG7A-BS-C</h3>
                        <ul>
                         <li>Actual capacity : 7Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 105A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from YTX7A (Actual capacity : 7Ah)</li>
                        <li>Recommended for Benelli TNT150i and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 5,699 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i></a>
                </li>
                <li class="product" onClick={showModal}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider7} />
                        <h3>MG9-BS-C</h3>
                        <ul>
                         <li>Actual capacity : 9Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 120A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETZ-9R (Actual capacity : 8Ah)</li>
                        <li>Recommended for Royal Enfield 350 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 5,999 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i></a>
                </li>
                <li class="product" onClick={showModal}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider8} />
                        <h3>MG5-3B-C</h3>
                        <ul>
                         <li>Actual capacity : 5Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 65A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETX-5L (Actual capacity : 5Ah)</li>
                        <li>Recommended for Yamaha FZ 150 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 3,299 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i></a>
                </li>

                <li class="product" onClick={showModal}>
                    
                        
                        <img alt="" class="attachment-shop_catalog " src={Slider9} />
                        <h3>MG5L-BS-C</h3>
                        <ul>
                         <li>Actual capacity : 5Ah</li>
                        <li>Cold Cranking Amperes (-18° EN Standard) : 75A</li>
                        <li>Operating Discharge Temperature Range : -30°C to 50°C</li>
                        <li>Upgrade from ETZ-5L (Actual capacity : 4Ah)</li>
                        <li>Recommended for Bajaj Pulsar 150 and similar two-wheelers</li>
                         </ul>
                        <span class="price">
                           
                            <ins> <span class="amount"><b>MRP</b> 3,299 </span> </ins>
                            
                        </span>
                   

                    <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i></a>
                </li>
            </Carousel>
            </div>

            <Modal title="Product Enquiry Form" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <form onSubmit={handleSubmit} className="contFrm" >
                                <div className="row">
                                    <div className="col-sm-6">
                                        <input type="text" name="name" placeholder="Your Name" className="inptFld" required  onChange={(event) => setFormData({ ...formData, name: event.target.value })}/>
                                    </div>

                                    <div className="col-sm-6">
                                        <input type="email" name="email" placeholder="Email Address" className="inptFld" required  onChange={(event) => setFormData({ ...formData, email: event.target.value })}/>
                                    </div>

                                    <div className="col-sm-6">
                                        <input type="tel" name="phone" placeholder="Phone Number" className="inptFld" required  onChange={(event) => setFormData({ ...formData, phone: event.target.value })}/>
                                    </div>

                                    <div className="col-sm-6">
                                        <input type="text" name="subject" placeholder="Subject" className="inptFld" required  onChange={(event) => setFormData({ ...formData, subject: event.target.value })}/>
                                    </div>

                                    <div className="col-12">
                                        <textarea className="inptFld" rows="" cols="" placeholder="Your Message..." required name="message"  onChange={(event) => setFormData({ ...formData, message: event.target.value })}></textarea>
                                    </div>

                                    <div className="col-12">
                                    <button type="submit">Submit</button>
                                
                                    </div>
                                </div>
                            </form>
      </Modal>
        </div>
    )
}